
import { Component, Vue } from 'vue-property-decorator'
import ApiKeyList from '@/components/ApiKeyList.vue'

@Component({
  components: {
    ApiKeyList
  }
})
export default class ApiKeys extends Vue {}
